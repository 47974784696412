import serverCheck from "../../modules/serverCheck";
import toast from "../../modules/toast";
import BaseService from "../BaseService";

/**
 * Service for fetching enumerations from the customers backend service.
 */
class CustomersEnumsService extends BaseService {
  baseUrl = `${serverCheck.getServer().serverCustomer}`;

  cacheOptions = {
    prefix: "customers-enums",
    ttl: 30 // 30 minutes
  };

  /**
   * @returns {Promise<CountryAPIModel[]>}
   */
  async countries() {
    try {
      const res = await this.httpClient.get("country");
      return res.data.data;
    } catch (error) {
      toast.displayToast({
        type: "error",
        titleText: "Error",
        bodyMessageText: error.response?.data?.errorMessage ?? error.message
      });
      throw error;
    }
  }

  /**
   * @returns {Promise<StateAPIModel[]>}
   */
  async states() {
    try {
      const res = await this.httpClient.get("enums/states");
      return res.data.data;
    } catch (error) {
      toast.displayToast({
        type: "error",
        titleText: "Error",
        bodyMessageText: error.response?.data?.errorMessage ?? error.message
      });
      throw error;
    }
  }

  /** @returns {Promise<BCGLCodeAPIModel[]>} */
  async bcGlCodes({ glCodeType } = { glCodeType: "CUSTOMER" }) {
    try {
      const res = await this.httpClient.get("enums/bcGlCodes", {
        params: { glCodeType }
      });
      return res.data.data;
    } catch (error) {
      toast.displayToast({
        type: "error",
        titleText: "Error",
        bodyMessageText:
          "There was an error getting the nominal codes. Please try again."
      });
      throw error;
    }
  }
}

const customersEnumsService = new CustomersEnumsService();

export default customersEnumsService;

/**
 * @typedef {object} CountryAPIModel
 * @prop {number} countryId
 * @prop {string} name
 * @prop {string} code
 */

/**
 * @typedef {object} StateAPIModel
 * @prop {number} stateId
 * @prop {string} stateName
 * @prop {string} stateShortName
 */

/**
 * @typedef {object} BCGLCodeAPIModel
 * @prop {number} bcGlCodeId
 * @prop {string} bcGlCode
 * @prop {string} description
 * @prop {string} bcGlCodeType
 * @prop {0 | 1} activeInd
 */
