import { get } from "lodash";
import { getElement } from "../../helpers/htmlUtils";
import { removeReason } from "../forms";

const dataStore = new WeakMap();
const tailSelectInstances = new WeakMap();

/**
 * @template {object} T
 * @param {object} param0
 * @param {string | HTMLSelectElement} param0.select
 * @param {string} [param0.defaultValue]
 * @param {string} [param0.customLabelClass]
 * @param {string} param0.placeholder
 * @param {SelectComponentOptions} [param0.options] As described on tail select https://github.com/wolffe/tail.select.js/wiki/public-options
 * @param {T[]} param0.data
 * @param {(opt: T) => string} [param0.getAttrs]
 * @param {(opt: T) => string} [param0.getLabel] Function to compute label dynamically.
 * @param {keyof T} [param0.labelKey] Key to read the label from. Can be path using dot notation. Can be omitted when getLabel is provided.
 * @param {(opt: T) => string} [param0.getValue] Function to compute value dynamically.
 * @param {keyof T} [param0.valueKey] Key to read the value from. Can be path using dot notation. Can be omitted when getValue is provided.
 * @param {boolean} [param0.showPlaceholderAsOption] If the placeholder should be added as an option.
 * @param {(event: { data: T } & Record<string, unknown>, state: string) => void} [param0.onChange] The onChange event as described on tail select docs. https://github.com/wolffe/tail.select.js/wiki/Events-Callbacks#change.
 *  Addition of `data` key that contains the item's initial data and `tailSelect` containing the tail select instance.
 */
export function createSelectComponent({
  select,
  defaultValue = "",
  customLabelClass,
  options,
  data,
  getAttrs,
  labelKey,
  valueKey,
  getLabel,
  getValue,
  placeholder,
  onChange,
  showPlaceholderAsOption
}) {
  const selectEl = getElement(select);
  if (!selectEl) return console.error("Select element is not found");

  const dataMap = new Map();
  let selectOptions = data
    .map((opt) => {
      const value = getValue ? getValue(opt) : get(opt, valueKey);
      const label = getLabel ? getLabel(opt) : get(opt, labelKey);
      const attrs = getAttrs ? getAttrs(opt) : "";
      dataMap.set(String(value), opt);
      return `<option value="${value}" ${
        value === defaultValue ? "selected" : ""
      } ${attrs}>${label}</option>`;
    })
    .join("");

  if (showPlaceholderAsOption) {
    selectOptions = `<option value="">${placeholder}</option>` + selectOptions;
  }

  selectEl.innerHTML = selectOptions;
  selectEl.value = defaultValue;
  dataStore.set(selectEl, dataMap);

  if (tailSelectInstances.has(selectEl))
    tailSelectInstances.get(selectEl).remove();

  const tailSelectInstance = tailSelect(selectEl, {
    hideDisabled: false,
    cbComplete(tSelect) {
      const labelInner = tSelect.querySelector(".label-inner");
      const selectedOption = tSelect.querySelector(".dropdown-option.selected");

      if (
        labelInner &&
        selectedOption &&
        labelInner.textContent !== selectedOption.textContent
      ) {
        if (customLabelClass)
          labelInner?.classList.replace("label-inner", customLabelClass);
        labelInner.textContent = selectedOption.textContent;
      }
    },
    placeholder,
    ...options
  });

  tailSelectInstance.reload();
  tailSelectInstance.on("change", (e, state) => {
    removeReason(selectEl);
    selectEl.closest(".input-base")?.classList.remove("error");

    if (onChange) {
      e.data = getSelectCurrentSelection(e.option.parentElement);
      e.tailSelect = tailSelectInstance;
      onChange(e, state);
    }
  });

  tailSelectInstances.set(selectEl, tailSelectInstance);

  return tailSelectInstance;
}

export function getSelectData(select) {
  const data = dataStore.get(getElement(select));
  if (!data) return null;
  return Object.values(Object.fromEntries(data));
}

export function getSelectCurrentSelection(select) {
  const selectEl = getElement(select);
  const data = dataStore.get(selectEl);
  if (!data) return null;
  return data.get(selectEl.value);
}

/**
 * @param {HTMLElement | string} select
 * @param {string | number | boolean} value
 * @param {boolean} [triggerChange] When true, change event will be fired. Defaults to `true`.
 */
export function setSelectSelectedOption(select, value, triggerChange = true) {
  if (value) {
    const tailSelectInstance = tailSelectInstances.get(getElement(select));
    if (tailSelectInstance) {
      // Tail select has the trigger change inverted. False would trigger a change and true won't.
      tailSelectInstance.options.handle(
        "select",
        value?.toString(),
        "#",
        !triggerChange
      );
    }
  } else {
    unselectSelectedOptions(select);
  }
}

/**
 * Set the selected options for a select component. Currently selected options will be unselected.
 * @param {HTMLElement | string} select
 * @param {Array<string | number>} values
 */
export function setSelectSelectedOptions(select, values) {
  unselectSelectedOptions(select);

  if (values?.length) {
    values.forEach((id, index, arr) => {
      setSelectSelectedOption(select, id, index === arr.length - 1);
    });
  }
}

/**
 * @param {HTMLElement | string} select
 */
export function unselectSelectedOptions(select) {
  const tailSelectInstance = tailSelectInstances.get(getElement(select));
  if (tailSelectInstance) {
    // Tailselect is mutating the array internally. Need to clone array in order to remove all options for multi-select
    const selectedOptions = [...tailSelectInstance.options.selected];
    selectedOptions.forEach((opt) => {
      tailSelectInstance.options.handle("unselect", opt, "#", true);
    });
  }
}

export function isSelectComponent(select) {
  return !!tailSelectInstances.get(getElement(select));
}

/**
 * @param {HTMLSelectElement} select
 */
export function addSelectOption(select, { value, label, selected, disabled }) {
  const tailSelectInstance = tailSelectInstances.get(getElement(select));
  if (tailSelectInstance) {
    const option = new Option(String(label), String(value), selected, selected);
    option.disabled = disabled;
    select.options.add(option);
    tailSelectInstance.reload();
  }
}

/**
 * @param {HTMLSelectElement} select
 */
export function removeSelectOption(select, value) {
  const tailSelectInstance = tailSelectInstances.get(getElement(select));
  if (tailSelectInstance) {
    const index = Array.from(select.options).findIndex(
      (opt) => opt.value === String(value)
    );
    select.options.remove(index);
    tailSelectInstance.reload();
  }
}

export function removeSelectDisabledOptions(select) {
  const tailSelectInstance = tailSelectInstances.get(getElement(select));
  if (tailSelectInstance) {
    Array.from(select.options).forEach((opt) => {
      if (opt.disabled) {
        select.options.remove(opt.index);
      }
    });
    tailSelectInstance.reload();
  }
}

export function getSelectOption(select, value) {
  const tailSelectInstance = tailSelectInstances.get(getElement(select));
  if (tailSelectInstance) {
    return tailSelectInstance.options.get(value?.toString(), "#");
  }
}

/**
 * https://github.com/wolffe/tail.select.js/wiki/public-options
 * @typedef {object} SelectComponentOptions
 * @property {boolean} [animate]
 * @property {boolean|string|string[]|null} [classNames]
 * @property {boolean} [csvOutput]
 * @property {string} [csvSeparator]
 * @property {boolean} [descriptions]
 * @property {boolean} [deselect]
 * @property {boolean} [disabled]
 * @property {number|null} [height]
 * @property {boolean} [hideDisabled]
 * @property {boolean} [hideSelected]
 * @property {object} [items]
 * @property {string} [locale]
 * @property {object} [linguisticRules]
 * @property {boolean} [multiple]
 * @property {number} [multiLimit]
 * @property {boolean} [multiPinSelected]
 * @property {boolean|string} [multiContainer]
 * @property {boolean} [multiShowCount]
 * @property {boolean} [multiShowLimit]
 * @property {boolean} [multiSelectAll]
 * @property {boolean} [multiSelectGroup]
 * @property {boolean|null} [openAbove]
 * @property {string|null} [placeholder]
 * @property {boolean} [search]
 * @property {string[]} [searchConfig]
 * @property {boolean} [searchDisabled]
 * @property {boolean} [searchFocus]
 * @property {boolean} [searchMarked]
 * @property {number} [searchMinLength]
 * @property {boolean|string|Function} [sortItems]
 * @property {boolean|string|Function} [sortGroups]
 * @property {boolean} [sourceBind]
 * @property {boolean} [sourceHide]
 * @property {boolean} [startOpen]
 * @property {boolean} [stayOpen]
 * @property {number|string|null} [width]
 * @property {Function} [cbComplete]
 * @property {Function} [cbEmpty]
 * @property {Function} [cbLoopItem]
 * @property {Function} [cbLoopGroup]
 */
